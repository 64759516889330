<template>
  <div class="col-12" style="padding: 0px">
    <div class="card" style="padding: 0px">
      <div class="card-header">
        <h5>
          الطلبات <button class="btn btn-sm btn-primary" v-b-modal.create style="padding: 5px; border-radius: 50%">
            <i class="fa fa-plus"></i>
          </button>
        </h5>
      </div>
      <div class="card-body" style="padding: 0px">
        <div class="col-12 table-responsive"  style="padding: 0px">
          <table class="table table-hover table-bordered table-sm table-striped" style="padding: 0px">
            <thead>
              <th>
                م
              </th>
              <th>
                الاسم
              </th>
              <th>
                الجوال
              </th>
              <th>
                الحالة
              </th>
              <th>
                تاريخ الاجابة
              </th>
              <th>
                خيارات
              </th>
            </thead>
            <tbody>
              <tr v-for="(form, i) in list" :key="form._id">
                <td>
                  {{ i+1 }}
                </td>
                <td>
                  {{ form.name }}
                </td>
                <td>
                  {{ form.phone }}
                </td>
                <td>
                  <span class="btn-sm badge bg-success" style="border-radius: 0px" v-if="form.status">
                    <i class="fa fa-check"></i>
                    تم الانتهاء
                  </span>
                  <span class="btn-sm badge bg-warning" style="border-radius: 0px" v-if="!form.status">
                    <i class="fa fa-clock-o"></i>
                    في الانتظار
                  </span>
                </td>
                <td>
                  {{ form.end_date }}
                </td>
                <td>
                  <button v-if="form.status" style="border-radius: 0px; border:none" class="btn-sm btn btn-primary"  @click="current = form" v-b-modal.details>
                    <i class="fa fa-list"></i> التفاصيل
                  </button>
                  <button v-if="form.status" style="border-radius: 0px; border:none" class="btn-sm btn btn-success" @click="current = form" v-b-modal.accept>
                    <i class="fa fa-check"></i> موافقة
                  </button>
                  <button style="border-radius: 0px; border:none" class="btn-sm btn btn-info" title="الرابط" @click="current = form" v-b-modal.link>
                    <i class="fa fa-link"></i>
                  </button>
                  <button style="border-radius: 0px; border:none" class="btn-sm btn btn-danger" @click="current = form; deleteme()">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal id="accept" title="موافقة" hide-footer>
      <template v-if="current._id">
        <div class="form-group">
          <h5 for="">القسم</h5>
          <select class="form-control" v-model="current.info.section">
            <option v-for="i in sections" :key="i">{{ i }}</option>
          </select>
        </div>
        <div class="form-group">
          <h5 for="">الوظيفة</h5>
          <select class="form-control" @change="detectJobCout()" v-model="current.info.job">
            <option v-for="i in jobs" :key="i.code" :value="i.code">{{ i.title }}</option>
          </select>
        </div>
        <div class="form-group">
          <h5 for="">الراتب</h5>
          <input type="number" v-model="current.info.salary" class="form-control">
        </div>
        <div class="form-group">
          <h5 for="">المبلغ الإضافي</h5>
          <input type="number" v-model="current.info.extra" class="form-control">
        </div>
        <div class="form-group">
          <h5 for="">المكافأة</h5>
          <input type="number" v-model="current.info.bouns" class="form-control">
        </div>
        <div class="form-group">
          <h5 for="">اسم المستخدم</h5>
          <input type="text"
            class="form-control" v-model="current.info.username">
        </div>
        <div class="form-group">
          <h5 for="">كلمة المرور</h5>
          <input type="text"
            class="form-control" v-model="current.info.password">
        </div>
      </template>
      <div class="col-12 text-center g">
        <button class="btn btn-success" @click="accept(current)">
          <i class="fa fa-check"></i>
          موافقة الآن وانشاء الحساب والعقد
        </button>
        <br><br>
        <small>* ستؤدي الموافقة الى انشاء العقد في حساب الموظف.</small>
      </div>
    </b-modal>
    <b-modal id="details" title="التفاصيل" hide-footer size="lg">
      <div class="col-12 table-responsive" v-if="current._id" id="printme">
        <table class="table table-hover table-striped table-bordered table-sm">
          <tbody>
            <tr>
              <td>
                الاسم
              </td>
              <td>
                {{ current.content.name }}
              </td>
            </tr>
            <tr>
              <td>
                الجوال
              </td>
              <td>
                {{ current.content.phone }}
              </td>
            </tr>
            <tr>
              <td>
                البريد الالكتروني
              </td>
              <td>
                {{ current.content.email }}
              </td>
            </tr>
            <tr>
              <td>
                رقم الهوية
              </td>
              <td>
                {{ current.content.number }}
              </td>
            </tr>
            <tr>
              <td>
                مصدر الهوية
              </td>
              <td>
                {{ current.content.number_source }}
              </td>
            </tr>
            <tr>
              <td>
                اسم المحرم "خاص بالنساء"
              </td>
              <td>
                {{ current.content.muhrim }}
              </td>
            </tr>
            <tr>
              <td>
                رقم هوية المحرم "خاص بالنساء"
              </td>
              <td>
                {{ current.content.muhrim_number }}
              </td>
            </tr>
            <tr>
              <td>
                جوال المحرم "خاص بالنساء"
              </td>
              <td>
                {{ current.content.muhrim_phone }}
              </td>
            </tr>
            <tr>
              <td>
                تاريخ الميلاد بالتقويم الملادي
              </td>
              <td>
                {{ current.content.birth_date }}
              </td>
            </tr>
            <tr>
              <td>
                الجنس
              </td>
              <td v-if="current.content.gender">
                {{ current.content.gender.replace("man", 'ذكر').replace("woman", "انثى") }}
              </td>
            </tr>
            <tr>
              <td>
                الجنسية
              </td>
              <td>
                {{ current.content.national }}
              </td>
            </tr>
            <tr>
              <td>
                رقم الحساب البنكي ( الآيبان ) بدون ( SA )
              </td>
              <td>
                {{ current.content.bank_number }}
              </td>
            </tr>
            <tr>
              <td>
                البنك الذي عليه الحساب 
              </td>
              <td>
                {{ current.content.bank }}
              </td>
            </tr>
            <tr>
              <td>
                صورة من الهوية الوطنية<br> أو الإقامة سارية بنفس النموذج المرفق 
              </td>
              <td>
                <a :href="current.content.file_1" target="_blank"><i class="fa fa-external-link"></i> عرض</a>
              </td>
            </tr>
            <tr>
              <td>
                شهادة الآيبان الخاص بالحساب البنكي للمتقدم
              </td>
              <td>
                <a :href="current.content.file_2" target="_blank"><i class="fa fa-external-link"></i> عرض</a>
              </td>
            </tr>
            <tr>
              <td>
                الصورة الشخصية لإصدار بطاقة نسك
              </td>
              <td>
                <a :href="current.content.file_3" target="_blank"><i class="fa fa-external-link"></i> عرض</a>
              </td>
            </tr>
            <tr>
              <td>
                هل سبق لك الحج ؟
              </td>
              <td>
                {{ current.content.hij_before == 'yes' ? 'نعم' : 'لا' }}
              </td>
            </tr>
            <tr>
              <td>
                هل سبق لك أن عملت في حملات الحج ؟
              </td>
              <td>
                {{ current.content.work_before == 'yes' ? 'نعم' : 'لا' }}
              </td>
            </tr>
            <tr>
              <td>
                اكتب عن نفسك وخبراتك
              </td>
              <td>
                {{ current.content.about }}
              </td>
            </tr>
            <tr>
              <td>
                آخر مؤهل تم الحصول عليه
              </td>
              <td>
                {{ current.content.last_degree }}
              </td>
            </tr>
            <tr>
              <td>
                صورة المؤهل
              </td>
              <td>
                <a :href="current.content.file_4" target="_blank"><i class="fa fa-external-link"></i> عرض</a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-12 text-center g">
          <button class="btn btn-warning" @click="printme()">
            <i class="fa fa-print"></i>
            طباعة الجدول
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal id="link" title="الرابط" hide-footer>
      <div class="form-group">
        <label for="">الرابط</label>
        <input type="text"
          class="form-control text-center" readonly :value="`https://joinbashaer.brmja.com/join/${current.code}`">
      </div>
      <div class="col-12 text-center g">
        <button class="btn btn-sm btn-success" @click="sendWhatsApp(current.phone, `https://joinbashaer.brmja.com/join/${current.code}`)">
          <i class="fa fa-whatsapp"></i>
          ارسال بالواتسآب
        </button>
      </div>
    </b-modal>
    <b-modal id="create" title="انشاء رابط" hide-footer>
      <div class="form-group">
        <h5 for="">الاسم</h5>
        <input type="text"
          class="form-control" v-model="create.name" placeholder="اكتب هنا...">
      </div>
      <div class="form-group">
        <h5 for="">الجوال (بالصيغة الدولية)</h5>
        <input type="text"
          class="form-control" v-model="create.phone" placeholder="اكتب هنا...">
      </div>
      <div class="form-check">
        <label class="form-check-label">
          <input type="checkbox" class="form-check-input" v-model="create.send" value="true" checked>
          ارسال الرابط عبر الواتسآب
        </label>
      </div>
      <div class="col-12 text-center g">
        <button class="btn btn-primary" @click="createNow()">
          <i class="fa fa-check"></i>
          انشاء الطلب
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            create: {send: true},
            list: [],
            current: {},
            jobs: [],
            sections: []
        }
    },
    created(){
        if(!checkPer('jobs')){
            this.$router.push('/pers')
            return;
        }
        this.getList()
        var g = this;
        $.post(api + '/admin/web/settings', {
            jwt: g.user.jwt
        }).then(function(r){
          g.jobs = JSON.parse(r.response.jobs)
          g.sections = (r.response.sections ?? "").split(",")
        })
    },
    methods: {
      deleteme(){
        var g = this;
        if(confirm("متأكد من حذفها نهائيا؟")){
          $.post(api + '/admin/forms/delete', {
            jwt: g.user.jwt,
            id: g.current._id,
        }).then(function(r){
            alert("تم الحذف بنجاح", 100)
            g.getList()
        })
        }
      },
      detectJobCout(){
        var g = this;
        $.post(api + '/admin/forms/job-count', {
          jwt: g.user.jwt,
          code: g.current.info.job,
        }).then(function(r){
            if(r.status == 200){
              alert(r.response, 200)
            }else{
              g.current.info.salary = r.response.salary;
              g.current.info.bouns = r.response.bouns;
              g.current = JSON.parse(JSON.stringify(g.current))
            }
        })
      },
      printme(){
        var win = window.open()
        win.document.write(`
        
        <html dir='rtl'>
          <head>
            <style>
            table, td, th {
                border: 1px solid;
              }

              table {
                width: 100%;
                border-collapse: collapse;
              }
              button{
              display:none}
              @page{
              margin: 0px}
            </style>
          </head>
          <body>
            ${$("#printme").html()}
          </body>
        </html>
        `)
        win.document.close()
        win.print()
      },
      accept(item){
        var g = this;
        $.post(api + '/admin/forms/accept', {
            jwt: g.user.jwt,
            id: item._id,
            info: item.info
        }).then(function(r){
            alert("تم الموافقة بنجاح", 100)
            g.getList()
        })
      },
      getList(){
        var g = this;
        $.post(api + '/admin/forms/list', {
            jwt: g.user.jwt,
        }).then(function(r){
            g.list = r.response.map(function(x){ 
              if(x.content){x.content = JSON.parse(x.content)};
              if(x.info){x.info = JSON.parse(x.info)}else{
                x.info = {}
              };
              x.info.username = Math.random().toString().split(".")[1].substring(0,5);
              x.info.password = Math.random().toString().split(".")[1].substring(0,5);
              return x;});
        })
      },
      createNow(){
        var g = this;
        if(g.create.name && g.create.name != ""){
          $.post(api + '/admin/forms/create', {
                jwt: g.user.jwt,
                name: g.create.name,
                phone: g.create.phone,
                send: g.create.send,
            }).then(function(r){
                alert("تم الانشاء بنجاح", 100)
                g.getList()
            })
        }else{
          alert("الاسم مطلوب", 200)
        }
      },
      sendWhatsApp(phone, message){
        var g = this;
        $.post(api + '/admin/forms/send-message', {
            jwt: g.user.jwt,
            phone: phone,
            message: message
        }).then(function(r){
            alert("تم الارسال بنجاح", 100)
            g.getList()
        })
      }
    }
}
</script>

<style>

</style>